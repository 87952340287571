import "./EditDiscountCodeElement.less";
import { DiscountCode } from "./DiscountCode";

interface Game { 
    id: string;
    name: string;
    isPublic: boolean;
}

class EditDiscountCodeElement extends HTMLElement {
    static tag = "edit-discount-code";
    private _createButton: HTMLButtonElement;
    private _deleteButton: HTMLButtonElement;
    private _enableExpire: HTMLInputElement;
    private _expiration: HTMLInputElement;
    private _gameHelper: HTMLSelectElement;
    private _gameIds: HTMLTextAreaElement;
    private _amount: HTMLInputElement;
    private _percentage: HTMLInputElement;
    private _mode: "create" | "edit" = "create"; 
    private _id?: string;
    

    private readGames() : Game[] { 
        const scriptElement = this.querySelector("script[type='application/json+games']") as HTMLScriptElement;
        const games = JSON.parse(scriptElement.textContent);
        scriptElement.remove();

        // sort by name
        games.sort((a, b) => a.name.localeCompare(b.name));

        return games.filter(m => m.isPublic);
    }

    private readCode() : DiscountCode | undefined { 
        const scriptElement = this.querySelector("script[type='application/json+code']") as HTMLScriptElement;

        if (scriptElement == null)
            return undefined;

        const discountCode = JSON.parse(scriptElement.textContent) as DiscountCode;
        scriptElement.remove();
        return discountCode;
    }

    connectedCallback() { 
        const games = this.readGames();

        const code = this.readCode();
        if (code) { 
            this._mode = "edit";
            this._id = code.id;
        }
            
        console.log(this.view(games, code));
        this.innerHTML = this.view(games, code);

        this._deleteButton = this.querySelector("button[name=delete]") as HTMLButtonElement;
        this._deleteButton?.addEventListener("click", this.deleteCode);

        this._createButton = this.querySelector("button[name=save]") as HTMLButtonElement;
        this._createButton.addEventListener("click", this.createSession);

        this._amount = this.querySelector("input[name=amount]") as HTMLInputElement;
        this._percentage = this.querySelector("input[name=percentage]") as HTMLInputElement;

        this._amount.addEventListener("input", this.updateDisabledFields);
        this._percentage.addEventListener("input", this.updateDisabledFields);

       

        this._gameHelper = this.querySelector("select[name=game-selector]") as HTMLSelectElement;


        this._gameIds = this.querySelector("textarea[name=game-ids]") as HTMLTextAreaElement;
       

        this._gameHelper.addEventListener("change", () => {
            

            if (this._gameIds.value.length > 0)
                this._gameIds.value += "\n";

            this._gameIds.value += this._gameHelper.value;

            this._gameHelper.value = "";
        });

        this.updateDisabledFields();
    }

    private updateDisabledFields = () => { 
        if (this._amount.value.length > 0)
            this._percentage.disabled = true;
        else
            this._percentage.disabled = false;

        if (this._percentage.value.length > 0)
            this._amount.disabled = true;
        else
            this._amount.disabled = false;
    }


    disconnectedCallback() { 
        this._createButton.removeEventListener("click", this.createSession);
    }

    private toggleExpire = () => { 
        this._expiration.disabled = !this._enableExpire.checked;
    }

    private deleteCode = () => { 
        if (!confirm("Are you sure you want to delete this discount code?"))
            return;

        fetch(`/admin/discount-codes/${this._id}`, {
            method: "DELETE"
        }).then(response => {
            if (!response.ok)
                alert("Failed to delete discount code");
            else
                document.location.href = "/admin/discount-codes";
        });
    }
    
    private view = (games: Game[], discountCode?: DiscountCode) => `
        <table>
            <tr>
                <td>Games</td>
                <td>
                    <select name="game-selector" required>
                        <option value="">Select a game</option>
                        ${games.map(g => `<option value="${g.id}">${g.name}</option>`).join("")}
                    </select>
                    <p><textarea name=game-ids>${discountCode?.gameIds?.split(",").map(m => m.trim()).join("\n") || ""}</textarea></p>
                </td>
            </tr>
             <tr>
                <td>Full code</td>
                <td>
                    <input type=text name=fullCode value="${discountCode?.fullCode || ""}" />
                </td>
            </tr>
            <tr>
                <td>Description (will appear on order)</td>
                <td>
                    <input type=text name=description value="${discountCode?.description || ""}" />
                </td>
            </tr>
            <tr>
                <td>Note (internal use)</td>
                <td>
                    <input type=text name=note value="${discountCode?.note || ""}" />
                </td>
            </tr>
             <tr>
                <td>Discount Amount in percent</td>
                <td>
                    <input type=number name=percentage value="${discountCode?.percentage || ""}" />
                </td>
            </tr>
            <tr>
                <td>Discount Amount in euro cents</td>
                <td>
                    <input type=number name=amount value="${discountCode?.amount || ""}" />
                </td>
            </tr>
            <tr>
                <td>Number of usages</td>
                <td>
                    <input type=number name=usages value="${discountCode?.usages || "2147483645"}" />
                </td>
            </tr>
        </table>
        <div name=code-box>
            <input type=text />
        </div>

        <div class=button-bar>
        <button type=button name=save>${this._mode === "edit" ? "Update" : "Create"}</button>

        ${this._mode === "edit" ? `<button type=button name=delete>Delete</button>` : ""}

        <form action="/admin/discount-codes" method=get>
            <button type=submit>< Back</button>
        </form>
        </div>        
    `;

    private createSession = async () => { 
        const gameIds = this._gameIds.value.split("\n").filter(m => m.length > 0).join(",");

        const response = await fetch(`/admin/discount-codes${this._mode === "edit" ? `/${this._id}` : ""}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                gameIds: gameIds,
                fullCode: (<HTMLInputElement>this.querySelector("input[name=fullCode]")).value,
                description: (<HTMLInputElement>this.querySelector("input[name=description]")).value,
                note: (<HTMLInputElement>this.querySelector("input[name=note]")).value,
                amount: (<HTMLInputElement>this.querySelector("input[name=amount]")).value || undefined,
                percentage: (<HTMLInputElement>this.querySelector("input[name=percentage]")).value || undefined,
                usages: (<HTMLInputElement>this.querySelector("input[name=usages]")).value,
            })
        });

        if (!response.ok)
            alert("Failed to create session");
        else { 
            if (this._mode === "edit") { 
                document.location.href = "/admin/discount-codes";
                return;
            }
                

            const codeBox = this.querySelector("div[name=code-box]") as HTMLDivElement;
            const code = await response.text();

            codeBox.style.display = "block";
            codeBox.querySelector("input").value = code;
        }
    }
}

customElements.define(EditDiscountCodeElement.tag, EditDiscountCodeElement);