// import { CitylineFactory } from "../Cityline/CitylineFactory";
//import { CitylineClient } from "../Cityline/CitylineClient";
import { CitylineNoAuthFactory } from "../Cityline/CitylineNoAuthFactory";
import EventTarget from "@ungap/event-target";

interface EventMap {
    "new-message": SupportMessage;
    "clear-unread": void;
}

export class SupportStore { 
    private eventTarget = new EventTarget();
    private static _instance = new SupportStore(); 
    private _cityline: any;
    static get instance() { return SupportStore._instance; }
    private constructor() { }
    private _messages: SupportMessage[] = [];
    private _initialSystemMessage: SupportMessage = {
        text: "Please leave your email to get answers outside of the game.",
        sender: "Support",
        metadata: {
            isSystem: "true"
        }
    }

    private _secondSystemMessage: SupportMessage = {
        text: "You may close this box; you will be notified of any replies.",
        sender: "Support",
        metadata: {
            isSystem: "true"
        }
    }

    async initialize() { 
        if (this._cityline)
            return;
        
        this._cityline = new CitylineNoAuthFactory().client;
        this._cityline.start();
        this.setupEventListeners();

        await this.bootstrapMessages();
    }

    async initializeInGame() { 
        if (this._cityline)
            return;
        
        this._cityline = (<any>window).cityline();
        this.setupEventListeners();

        await this.bootstrapMessages();
    }

    private async bootstrapMessages() { 
        const initial = await this._cityline.getFrame("support") as SupportMessage[];
        this._messages = [this._initialSystemMessage].concat(initial);
    }

    private setupEventListeners() { 
        this._cityline.addEventListener("support", (event: CustomEvent<SupportMessage>) => {
            if (Array.isArray(event.detail)) {
                event.detail.forEach(message => {
                    this._messages.push(message);
                    this.emit("new-message", message);
                });
                return;
            } 

            this._messages.push(event.detail);
            this.emit("new-message", event.detail);

            if (this._messages.length === 2) { 
                this._messages.push(this._secondSystemMessage);
                this.emit("new-message", this._secondSystemMessage);
            }
        });
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: value }));
    }

    public getMessages = async () => { 
        return this._messages;
    }

    public send = async (text: string, sender: string): Promise<boolean> => { 
        return await this._cityline.send("support", {
            text: text,
            sender: sender
        });
    }

    public clearUnread = () => { 
        this.emit("clear-unread", undefined);
    }
} 

export interface SupportMessage { 
    text: string;
    sender: string;
    created?: string;
    metadata: { [key: string]: string };
}