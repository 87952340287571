import "./CreateSessionElement.less";

interface Game { 
    id: string;
    name: string;
    isPublic: boolean;
}

class CreateSessionElement extends HTMLElement {
    static tag = "create-session";
    private _createButton: HTMLButtonElement;
    private _enableExpire: HTMLInputElement;
    private _expiration: HTMLInputElement;

    private readGames() { 
        const scriptElement = this.querySelector("script");
        const games = JSON.parse(scriptElement.textContent);
        scriptElement.remove();

        // sort by name
        games.sort((a, b) => a.name.localeCompare(b.name));

        return games.filter(m => m.isPublic);
    }

    connectedCallback() { 
        
        
        this.innerHTML = this.view(this.readGames());
        this._createButton = this.querySelector("button") as HTMLButtonElement;
        this._createButton.addEventListener("click", this.createSession);

        this._enableExpire = this.querySelector("[name=enableExpire]") as HTMLInputElement;

        this._enableExpire.addEventListener("input", this.toggleExpire);

        this._expiration = this.querySelector("input[name=expire]") as HTMLInputElement;

        var today = new Date();
        today.setFullYear(today.getFullYear() + 1);
        this._expiration.value = today.toISOString().substr(0, 10); 
    }

    disconnectedCallback() { 
        this._createButton.removeEventListener("click", this.createSession);
    }

    private toggleExpire = () => { 
        this._expiration.disabled = !this._enableExpire.checked;
    }
    
    private view = (games: Game[]) => `
        <table>
            <tr>
                <td>Game</td>
                <td>
                    <select name="game-id" required>
                        <option value="">Select a game</option>
                        ${games.map(g => `<option value="${g.id}">${g.name}</option>`).join("")}
                       
                    </select>
                </td>
            </tr>
            <tr>
                <td>Comment</td>
                <td>
                    <input type=text name=comment />
                </td>
            </tr>
            <tr>
                <td>Email</td>
                <td>
                    <input type=text name=email />
                </td>
            </tr>
            <tr>
                <td>Expires</td>
                <td>
                    <input type=checkbox name=enableExpire checked />
                    <input type=date name=expire />
                </td>
            </tr>
        </table>
        <div name=code-box>
            <input type=text />
        </div>
        <button type=button>Create</button>

        
    `;

    private createSession = async () => { 
        const response = await fetch("/admin/sessions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                gameId: (<HTMLInputElement>this.querySelector("select[name=game-id]")).value,
                comment: (<HTMLInputElement>this.querySelector("input[name=comment]")).value,
                email: (<HTMLInputElement>this.querySelector("input[name=email]")).value,
                expire: this._enableExpire.checked ? this._expiration.value : undefined
            })
        });

        if (!response.ok)
            alert("Failed to create session");
        else { 
            const codeBox = this.querySelector("div[name=code-box]") as HTMLDivElement;
            const code = await response.text();

            codeBox.style.display = "block";
            codeBox.querySelector("input").value = code;
        }
    }
}

customElements.define(CreateSessionElement.tag, CreateSessionElement);