import { DiscountCode } from "./DiscountCode";
import "./DiscountCodeViewerElement.less";
import { shortDate, shortDateTime, shortSystemDate } from 'Library/Formatting'; 

export class DiscountCodeViewerElement extends HTMLElement {
    public static tag = "discount-code-viewer";
    private _tbody: HTMLElement;
     
    
    public connectedCallback() {
        this.innerHTML = this.view();
        this._tbody = this.querySelector("tbody") as HTMLElement;
         
       
        this.refresh();
    }

    disconnectedCallback() {
         
 
    }

   

    private refresh = async () => {
        var search = new URLSearchParams();

       


        const response = await fetch("/admin/discount-codes/list?" + search.toString(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok)
            alert("Failed to load sessions");
        

        const sessions = await response.json() as DiscountCode[];

        this._tbody.innerHTML = this.sessionsView(sessions);
    }

    private formatDate = (source: string) => source ? shortDate(new Date(source)) : "-";

    private formatDateTime = (source: string) => source ? shortDate(new Date(source)) : "-";
    private formatSystemDate = (source: string) => source ? shortSystemDate(new Date(source)) : "";

  
    private static convertToUTC = (dateString) => {
        if (!dateString || dateString === "undefined")
            return undefined;
        
        const date = new Date(dateString);
        const utcString = date.toISOString();
        return utcString;
    }
 


   

    private sessionsView = (sessions: DiscountCode[]) => `
            ${sessions.map(s => `
            <tr>
                <td title="${s.created || ""}">${this.formatDateTime(s.created)}</td>
                <td title="${s.updated || ""}">${this.formatDateTime(s.updated)}</td>
                <td>${s.fullCode || ""}</td>
                <td>${s.prefix || ""}</td>
                <td>${s.gameIds || ""}</td>
                <td>${s.description || ""}</td>
                <td>${s.note || ""}</td>
                <td>${s.percentage === undefined ? "" : s.percentage}%</td>
                <td>${s.amount || ""}</td>
                <td>${s.usages || ""}</td>
                <td>
                    <a href="/admin/discount-codes/${s.id}">Edit</a>
                </td>
            </tr>
        `).join("")}
    `;

    private view = () => `
        <table class="styled-table">
            <thead>
                <tr>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Full code</th>
                    <th>Prefix</th>
                    <th>Game(s)</th>
                    <th>Description</th>
                    <td>Note</td>
                    <th>Percentage</th>
                    <th>Amount</th>
                    <th>Usages</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            
            </tbody>
        </table>`;

}

customElements.define(DiscountCodeViewerElement.tag, DiscountCodeViewerElement);