import "./QuickLaunchElement.less";

interface Game { 
    id: string;
    name: string;
    isPublic: boolean;
}

class QuickLaunchElement extends HTMLElement { 
    static tag = "quick-launch";
    private _select: HTMLSelectElement;

    private readGames() { 
        const scriptElement = this.querySelector("script");
        const games = JSON.parse(scriptElement.textContent);
        scriptElement.remove();

        // sort by name
        games.sort((a, b) => a.name.localeCompare(b.name));

        return games.filter(m => m.isPublic);
    }

    connectedCallback() { 
        this.innerHTML = this.view(this.readGames());
        this._select = this.querySelector("select") as HTMLSelectElement;

        this._select.addEventListener("input", this.launch);
    }

    disconnectedCallback() { 
        this._select.removeEventListener("input", this.launch);
    }

    private launch = async () => { 
        const response = await fetch("/admin/sessions/quick", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                gameId: this._select.value
            })
        });

        if (!response.ok)
            alert("Failed to create session");
        else { 
            const url = await response.json();
            window.open(url, "_blank");
        }

        this._select.value = "";
    }


    private view = (games: Game[]) => `
        <select>
            <option value="">Quick Launch</option>
            ${games.map(g => `<option value="${g.id}">${g.name}</option>`).join("")}
        </select>
    `;
}

customElements.define(QuickLaunchElement.tag, QuickLaunchElement);