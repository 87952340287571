import { SupportMessage, SupportStore } from "./SupportStore";
import { TextBoxElement } from "./TextBoxElement";
const style = require("!!raw-loader!less-loader!./LiveSupportElement.less");

export class LiveSupportElement extends HTMLElement {
    static tag = "live-support";
    private _section: HTMLElement;
    private scrollBottom = () => this._section.scrollTo(0, this._section.scrollHeight);

    async connectedCallback() {
        const shadowDom = this.attachShadow({ mode: "open" });
 
        shadowDom.innerHTML = this.view();
 
        this._section = shadowDom.querySelector("section") as HTMLElement;

        shadowDom.querySelector("text-box")!.setAttribute("user-name", this.sender());
        
        SupportStore.instance.clearUnread();
        (async () => await this.connectedCallbackAsync())();
    }

    async connectedCallbackAsync() {
        if (this.hasAttribute("in-game")) {
            await SupportStore.instance.initializeInGame();
        } else { 
            await SupportStore.instance.initialize();
        }

        await this.fetchInitialMessages();

        SupportStore.instance.addEventListener("new-message", (event: CustomEvent<SupportMessage>) => {
            this._section.insertAdjacentHTML("beforeend", this.messageView(event.detail));
            this.scrollBottom();
        });        
    }

    private sender = () => { 
        return (this.getAttribute("user-name") || "").trim();
    }

    private fetchInitialMessages = async () => {
        const messages = await SupportStore.instance.getMessages();
        this._section.innerHTML = messages.map(this.messageView).join("");
        this.scrollBottom();

        requestAnimationFrame(() => {
            this._section.style.scrollBehavior = "smooth";
        });
    }

    private messageView = (message: SupportMessage) => message.metadata["isSystem"] === "true" ? `
    
        <div class=system-message>${message.text}</div>
    `: `
        <div class=message sender="${message.sender === this.sender() ? "me" : message.sender}" style="--color: ${this.getColor(message.sender ?? Math.random().toString())}" created="${message.created}">
            <div class=avatar>
                ${message.metadata["isAdmin"] === "true" ? require("./Images/zebra-escapes-keyhole-white.svg") : ""}
            </div>
            <div class=text>
                ${message.text || ""}
            </div>
        </div>
    `;

    private view = () => `
        <style>${style.default ?? style}</style>
        <div class=chat-window>
            <header>
                <h1>Feedback or questions?</h1>
                <p>Any questions will be answered as soon as possible.</p>
            </header>

            <section></section>

            <${TextBoxElement.tag}></${TextBoxElement.tag}>
        </div>
    `;

    hashString = (input: string): number => {
        let hash = 0;
        if (input.length === 0) {
            return hash;
        }
        for (let i = 0; i < input.length; i++) {
            const charCode = input.charCodeAt(i);
            hash = (hash << 5) - hash + charCode;
            hash = hash & hash; // Convert to 32-bit integer
        }
        return hash;
    }

    private getColor(name: string): string {
        if (name === "Admin") return "#302f32";

        const hash = this.hashString(name);
        const index = Math.abs(hash) % LiveSupportElement._baseColorsSortedByContrast.length;
        return LiveSupportElement._baseColorsSortedByContrast[index];
    }

    private static _baseColorsSortedByContrast = [
        "#817",
        "#36b",
        "#639",
        "#a35",
        "#c66",
        "#e94",
        "#09c",
        "#4d8",
        "#0bc",
        "#2cb"
    ]
}

customElements.define(LiveSupportElement.tag, LiveSupportElement);

