import { UrlProvider } from "Library/UrlProvider";
import { CitylineClient } from "./CitylineClient"; 


export class CitylineNoAuthFactory {
    private static _client: CitylineClient;
    get client() {
        const conversationId = window.location.href.split("/").pop();

        return CitylineNoAuthFactory._client || (CitylineNoAuthFactory._client = new CitylineClient(() => ({
            apiEndpoint: `${UrlProvider.root}/cityline`,
            socketEndpoint: `${UrlProvider.root}/cityline`.replace("https", "wss"),
            headers: {
                "conversation-id": conversationId,
                "ze-authorization": document.cookie.match(/ze-authorization=([^;]+)/)[1]
            }
        })));
    }
}
