import { SupportStore } from "./SupportStore";

const style = require("!!raw-loader!less-loader!./TextBoxElement.less");

export class TextBoxElement extends HTMLElement { 
    static tag = "text-box";
    private _textarea: HTMLTextAreaElement;
    private _sendButton: HTMLButtonElement;

    connectedCallback() { 
        this.innerHTML = this.view();
        
        this._sendButton = this.querySelector("button[name=send]");
        this._sendButton.addEventListener("click", this.onSend); 

        this._textarea = this.querySelector("textarea");
        this._textarea.addEventListener("input", this.onInput);
        this._textarea.addEventListener("keydown", this.onKeyDown);

        this._textarea.focus();
    }

    private onKeyDown = (event: KeyboardEvent) => { 
        if (event.key === "Enter" && !event.shiftKey) { 
            event.preventDefault();
            this.onSend();
        }
    }

    private onInput = () => {   
        this._textarea.style.height = "auto";
        const height = Math.min(this._textarea.scrollHeight, 200) - 23;
        this._textarea.style.height = `${height}px`;
        
        console.log("height", height);

        this.parentElement.style.setProperty("--text-box-height", `${height}px`);
        this.registerText();
    }

    private registerText = () => { 
        this.toggleAttribute("has-text", this._textarea.value.trim() !== "");
    } 

    private onSend = async () => { 
        this.toggleAttribute("busy", true);
        try {
            await SupportStore.instance.send(this._textarea.value, this.getAttribute("user-name") ?? "Anonymous");
            this._textarea.value = "";
            this.onInput();
        } finally { 
            this.toggleAttribute("busy", false);
        }
        
    }

    private view = () => `
        <style>${style.default || style}</style>
        <textarea placeholder="Add comment ..."></textarea>
        <button name="send">${(require("./Images/send.svg"))}</button>`;

}

customElements.define(TextBoxElement.tag, TextBoxElement);
