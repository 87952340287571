
export function pad(no: number, wantedLength = 2, symbol = "0") {
    const source = no.toString();
    return source.length >= wantedLength ? source : new Array(wantedLength - source.length + 1).join(symbol) + source;
}

export function shortDate(date: Date): string {
    return `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(date.getDate())}`;
}

export function shortSystemDate(date: Date): string {
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}

export function yearMonth(date: Date): string {
    return `${date.getFullYear()}/${pad(date.getMonth() + 1)}`;
}

export function shortDateTime(date: Date): string {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${pad(date.getMinutes())}`;
}


export function htmlEscape(literals, ...placeholders) {
    let result = "";

    // interleave the literals with the placeholders
    for (let i = 0; i < placeholders.length; i++) {

        if (literals[i]) {
            if (!literals[i].endsWith("$"))
                result += literals[i];
            else
                result += literals[i].substring(0, literals[i].length - 1);
        }


        if (placeholders[i])
            if (Array.isArray(placeholders[i]))
                result += placeholders[i].join("");
            else if (literals[i].endsWith("$"))
                result += placeholders[i];
            else if (placeholders[i] && placeholders[i].toLocaleLowerCase && placeholders[i].toLocaleLowerCase().indexOf("<svg") === 0)
                result += placeholders[i];
            else if (placeholders[i] === undefined)
                result += "NULL";
            else if (typeof (placeholders[i]) === "number")
                result += placeholders[i];
            else if (typeof (placeholders[i]) === "boolean")
                result += placeholders[i].toString();
            else {
                if (!placeholders[i].replace)
                    throw new Error(`Cannot replace on value ${placeholders[i]}, index ${i} type is ${typeof (placeholders[i])}`);
                else
                    result += placeholders[i]
                        .replace(/&/g, "&amp;")
                        .replace(/"/g, "&quot;")
                        .replace(/'/g, "&#39;")
                        .replace(/</g, "&lt;")
                        .replace(/>/g, "&gt;");
            }

    }

    // add the last literal
    result += literals[literals.length - 1];
    return result;
}
