import "./SessionViewerElement.less";
import { shortDate, shortDateTime, shortSystemDate } from 'Library/Formatting';
import { UserPromptElement } from 'Feature/Dialog';
import * as kub from "@clickandmortar/kibana-url-builder";

export class SessionViewerElement extends HTMLElement {
    public static tag = "session-viewer";
    private _tbody: HTMLElement;
    private _joinCodeFilter: HTMLInputElement;
    private _playerNameFilter: HTMLInputElement;
    private _commentFilter: HTMLInputElement;
    private _gameIdFilter: HTMLSelectElement;
    private _showExpired: HTMLInputElement;
    private _showStarted: HTMLInputElement;
    private static kibanaLogo = `<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="2500" height="2500"><style>.st0{fill:#e9478b}.st1{fill:#3ebeb0}.st2{fill:#37a595}.st3{fill:#353535}.st4{fill:none}</style><path class="st0" d="M19.6 33.1c7.3 0 14.2 1.9 20.2 5.1l21.4-25.7H18.8v51-30.4h.8z"/><path class="st1" d="M39.8 38.2l-21 25.3v4h42.3c-2.4-12.6-10.4-23.3-21.3-29.3z"/><path class="st2" d="M39.8 38.2l-21 25.3v4h7.5l20.4-24.6s-1.4-1.1-3.3-2.5c-1.4-1-3.6-2.2-3.6-2.2z"/><path class="st3" d="M19.6 33.1h-.8v30.4l21-25.3c-6-3.2-12.9-5.1-20.2-5.1z"/><path class="st4" d="M0 0h80v80H0z"/></svg>`;

    public connectedCallback() {
        this.innerHTML = this.view();
        this._tbody = this.querySelector("tbody") as HTMLElement;
        this._joinCodeFilter = this.querySelector("input[name=join-code]") as HTMLInputElement;
        this._commentFilter = this.querySelector("input[name=comment]") as HTMLInputElement;
        this._gameIdFilter = this.querySelector("select[name=game-id]") as HTMLSelectElement;

        this._playerNameFilter = this.querySelector("input[name=player-name]") as HTMLInputElement;


        this._showExpired = this.querySelector("input[name=show-expired]") as HTMLInputElement;
        this._showStarted = this.querySelector("input[name=show-started]") as HTMLInputElement;

        this._commentFilter.addEventListener("keydown", this.keyHandler);
        this._joinCodeFilter.addEventListener("keydown", this.keyHandler);
        this._playerNameFilter.addEventListener("keydown", this.keyHandler);

        this._gameIdFilter.addEventListener("input", this.refresh);
        this._showExpired.addEventListener("input", this.refresh);
        this._showStarted.addEventListener("input", this.refresh);

        this.addEventListener("change", this.openKibana);

        this.addEventListener("input", this.updateExpiration);

        this.addEventListener("click", this.clickHandler);

        this.refresh();
    }

    disconnectedCallback() {
        this._commentFilter.removeEventListener("keydown", this.keyHandler);
        this._joinCodeFilter.removeEventListener("keydown", this.keyHandler);
        this._playerNameFilter.removeEventListener("keydown", this.keyHandler);

        this._gameIdFilter.removeEventListener("input", this.refresh);
        this._showExpired.removeEventListener("input", this.refresh);
        this._showStarted.removeEventListener("input", this.refresh);

        this.removeEventListener("click", this.clickHandler);

        this.removeEventListener("change", this.openKibana);
    }

    private updateExpiration = async (event: Event) => {
        if (!(event.target instanceof Element))
            return;


        if (event.target.getAttribute("name") !== "expiration")
            return;

        var target = event.target as HTMLInputElement;
        const sessionId = target.getAttribute("session-id");
        const date = target.value;

        await this.setExpiration(sessionId, date);


    }

    private openKibana = (event: Event) => {
        var target = event.target as HTMLSelectElement;

        const sessionId = target.getAttribute("session-id");


        switch (target.value) {
            case "dashboard":
                let from = SessionViewerElement.convertToUTC(target.getAttribute("from"));
                if (!from) 
                    from = "now-1d";
                    
                let to = SessionViewerElement.convertToUTC(target.getAttribute("to"));
                
                if (!to)
                    to = "now";
                
                window.open(SessionViewerElement.kibanaDashboard(sessionId, from, to), "_blank");
                break;
            case "session":
                window.open(SessionViewerElement.kibanaGameSession(sessionId), "_blank");
                break;
            case "events":
                window.open(SessionViewerElement.kibanaGameEvents(sessionId), "_blank");
                break;


        }
        target.selectedIndex = 0;
    }

    private clickHandler = async (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const td = event.target.closest("td[name=expiration]");
        if (td) {
            td.toggleAttribute("editing", true);

            const dateElement = td.querySelector("input") as HTMLInputElement;

            requestAnimationFrame(() => {
                (<any>dateElement).showPicker();
            });
        }


        const button = event.target.closest("button");
        if (!button)
            return;

        const sessionId = button.getAttribute("session-id");

        switch (button.name) {
            case "expire":
                this.expire(sessionId);
                break;
            case "ghost":
                this.ghost(sessionId);
                break;
            case "comment":
                this.comment(sessionId, button.getAttribute("comment") || "");
                break;
            case "setexpiration":
                button.parentElement.querySelector("input").toggleAttribute("show");
                break;
        }


    }

    private async comment(sessionId: string, comment: string) {

        const result = prompt("Enter a comment", comment);

        if (!result)
            return;

        if (result !== comment) {
            await this.doComment(sessionId, result);
        }
    }

    private async doComment(id: string, comment: string) {
        const response = await fetch("/admin/sessions/comment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: id, comment: comment })
        });

        if (!response.ok)
            alert("Failed to comment session");
        else {
            this.refresh();
        }
    }

    private async setExpiration(id: string, expiration: string) {
        const response = await fetch("/admin/sessions/expiration", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: id, expiration: expiration })
        });

        if (!response.ok)
            alert("Failed to set expiration on session");
        else {
            this.refresh();
        }
    }

    private async expire(sessionId: string) {
        const response = await UserPromptElement.show("Expire session", "Are you sure you want to expire this session?", [
            { id: "Cancel", title: "Cancel", intent: "secondary" },
            { id: "OK", title: "OK", intent: "primary" }]);

        if (response === "OK")
            await this.doExpire(sessionId);
    }

    private async ghost(sessionId: string) { 
        const response = await fetch(`/admin/sessions/ghost/${sessionId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            //body: JSON.stringify({ id: id })
        });

        if (!response.ok)
            alert("Failed to join session");
        else { 
            const url = await response.json();
            window.open(url, "_blank");
        }
    }

    private async doExpire(id: string) {
        const response = await fetch("/admin/sessions/expire", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: id })
        });

        if (!response.ok)
            alert("Failed to expire session");
        else {
            this.refresh();
        }
    }

    private keyHandler = (e: KeyboardEvent) => {
        if (e.key == "Enter") {
            this.refresh();
        }
    }

    private refresh = async () => {
        var search = new URLSearchParams();

        search.append("joinCode", this._joinCodeFilter.value);
        search.append("gameId", this._gameIdFilter.value);
        search.append("comment", this._commentFilter.value);
        search.append("expired", this._showExpired.checked ? "true" : "false");
        search.append("started", this._showStarted.checked ? "true" : "false");
        search.append("playerName", this._playerNameFilter.value);


        const response = await fetch("/admin/sessions/list?" + search.toString(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok)
            alert("Failed to load sessions");

        const sessions = await response.json() as GameSession[];

        this._tbody.innerHTML = this.sessionsView(sessions);
    }

    private formatDate = (source: string) => source ? shortDate(new Date(source)) : "-";

    private formatDateTime = (source: string) => source ? shortDateTime(new Date(source)) : "-";
    private formatSystemDate = (source: string) => source ? shortSystemDate(new Date(source)) : "";


    private static kibanaGameEvents = (id: string) => {
        const url = kub.buildDiscoverUrl({
            host: 'http://172.22.22.9:5601',
            columns: ['_source'],
            filters: [],
            index: "46fbe310-8029-11ec-8d4e-637d82857875",
            query: `gameId:${id}`,
            period: {
                from: "now-5y", to: "now", mode: "quick"
            },
        });
        return url;
    }


    private static convertToUTC = (dateString) => {
        if (!dateString || dateString === "undefined")
            return undefined;
        
        const date = new Date(dateString);
        const utcString = date.toISOString();
        return utcString;
    }

    private static kibanaDashboard = (id: string, from: string, to: string) => {
        return `http://172.22.22.9:5601/app/kibana#/dashboard/2a727b80-0473-11ee-9188-abfb55dfae65?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from}',mode:absolute,to:'${to}'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!t,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:9,i:'1',w:15,x:0,y:0),id:'92813c30-0472-11ee-9188-abfb55dfae65',panelIndex:'1',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:9,i:'3',w:13,x:15,y:0),id:'97e73800-051c-11ee-9188-abfb55dfae65',panelIndex:'3',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:9,i:'4',w:4,x:28,y:0),id:a62ebaf0-0535-11ee-9188-abfb55dfae65,panelIndex:'4',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:12,i:'5',w:48,x:0,y:9),id:'09188190-0569-11ee-9188-abfb55dfae65',panelIndex:'5',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:11,i:'6',w:48,x:0,y:21),id:b9ca2170-056d-11ee-9188-abfb55dfae65,panelIndex:'6',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:9,i:'7',w:16,x:32,y:0),id:a49d32d0-10d8-11ee-9188-abfb55dfae65,panelIndex:'7',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:52,i:'8',w:48,x:0,y:32),id:'63372f60-10da-11ee-9188-abfb55dfae65',panelIndex:'8',type:visualization,version:'6.8.23')),query:(language:lucene,query:'gameId:${id}%20OR%20id:${id}'),timeRestore:!f,title:'Game%20Session%20Dashboard',viewMode:view)`;

        var old =  `http://172.22.22.9:5601/app/kibana#/dashboard/2a727b80-0473-11ee-9188-abfb55dfae65?_g=(refreshInterval:(pause:!t,value:0),time:(from:'${from}',mode:absolute,to:'${to}'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(darkTheme:!t,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:9,i:'1',w:15,x:0,y:0),id:'92813c30-0472-11ee-9188-abfb55dfae65',panelIndex:'1',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:9,i:'3',w:13,x:15,y:0),id:'97e73800-051c-11ee-9188-abfb55dfae65',panelIndex:'3',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:9,i:'4',w:20,x:28,y:0),id:a62ebaf0-0535-11ee-9188-abfb55dfae65,panelIndex:'4',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:12,i:'5',w:48,x:0,y:9),id:'09188190-0569-11ee-9188-abfb55dfae65',panelIndex:'5',type:visualization,version:'6.8.23'),(embeddableConfig:(),gridData:(h:11,i:'6',w:48,x:0,y:21),id:b9ca2170-056d-11ee-9188-abfb55dfae65,panelIndex:'6',type:visualization,version:'6.8.23')),query:(language:lucene,query:'gameId:${id}%20OR%20id:${id}'),timeRestore:!f,title:'Game%20Session%20Dashboard',viewMode:view)`;
    }




    private static kibanaGameSession = (id: string) => {
        const url = kub.buildDiscoverUrl({
            host: 'http://172.22.22.9:5601',
            columns: ['_source'],
            filters: [],
            index: "c376f3a0-9d50-11ec-b2eb-e5ca3fa1e64d",
            query: `id:${id}`,
            period: {
                from: "now-5y", to: "now", mode: "quick"
            },
        });
        return url;
    }

    private sessionsView = (sessions: GameSession[]) => `
            ${sessions.map(s => `
            <tr>
                <td class=id title=${s.id}>${s.id}</td>
                <td>${s.joinCode}</td>
                <td>${s.gameId || ""}</td>
                <td title="${s.created || ""}">${this.formatDateTime(s.created)}</td>
                <td title="${s.started || ""}">${this.formatDateTime(s.started)}</td>
                <td title="${s.enriched || ""}">${this.formatDateTime(s.enriched)}</td>
                <td title="${s.expiration || ""}" name=expiration>
                    <span>${this.formatDate(s.expiration)}</span>
                    <input type=date name=expiration session-id="${s.id}" value="${this.formatSystemDate(s.expiration)}" />
                </td>
                <td class=support><a href="support/${s.id}">${s.supportSessionCount === 0 ? "🗣️" : s.supportSessionCount}</a></td>
                <td>${s.remoteIp || ""}</td>
                <td>${s.comment || ""}</td>
                <td>${s.players.map(m => m.name).join(",")}</td>
                <td>
                    <div class=actions>
                        <select session-id="${s.id}" from="${s.created}" to="${s.enriched}">
                            <option value="">💥</option>
                            <option value="dashboard">Dashboard</option>
                            <option value="session">Session</option>
                            <option value="events">Events</option>
                        </select>
                        <button type=button name=comment session-id="${s.id}" comment="${s.comment || ""}">💬</button> 
                        <button type=button name=expire session-id="${s.id}">🗑️</button>
                        <button type=button name=ghost session-id="${s.id}">👻</button>
                    </div>
                </td>
            </tr>
        `).join("")}
    `;

    private view = () => `
        <table class="styled-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Join Code</th>
                    <th>Game</th>
                    <th>Created</th>
                    <th>Started</th>
                    <th>Completed</th>
                    <th>Expires</th>
                    <th>Support</th>
                    <th>IP</th>
                    <th>Comment</th>
                    <th>Players</th>
                    <th></th>
                </tr>

                <tr class="filtering">
                    <td><input type="text" placeholder="..." /></td>
                    <td><input type="text" placeholder="..." name=join-code /></td>
                    <td>
                        <select name=game-id>
                            <option value="">All</option>
                            <option value="pirate-pursuit">Pirate Pursuit</option>
                            <option value="jp-hansen">JP Hanssen</option>
                            <option value="test-game">Test game</option>
                        </select>
                    </td>
                    <td><input type="text" placeholder="..." /></td>
                    <td><div><input type="checkbox" name=show-started />Only started</div></td>
                    <td><div><input type="checkbox" name=show-expired />Allow expired</div></td>
                    <td><input type="text" placeholder="---" name=ip /></td>
                    <td><input type="text" placeholder="---" name=comment /></td>
                    <td><input type="text" placeholder="..." name=player-name /></td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
            
            
               
                
                <tr class="active-row">
                    <td>Melissa</td>
                    <td>5150</td>
                </tr>
                <!-- and so on... -->
            </tbody>
        </table>`;

}

interface GameSession {
    id: string;
    joinCode: string;
    created: string;
    started: string;
    enriched: string;
    gameId: string;
    players: Player[];
    remoteIp: string;
    comment: string;
    expiration: string;
    supportSessionCount: number;
}

interface Player {
    name: string;
}


customElements.define(SessionViewerElement.tag, SessionViewerElement);