import "./TagToggleElement.less";

export class TagToggleElement extends HTMLElement { 
    static tag = "tag-toggle";

    static get observedAttributes() {
        return ["tags"];
    }

    connectedCallback() { 
        var tags = this.getAttribute("tags");
        var tagsArray = tags.split(",");

        this.innerHTML = this.view(tagsArray, this.loadInactiveTags());
        this.setAttribute("inactive-tags", this.loadInactiveTags().join(","));

        this.addEventListener("click", this.onClick);
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "tags") {
            var tagsArray = newVal.split(",");

            this.innerHTML = this.view(tagsArray, this.loadInactiveTags());
        }   
    }

    private onClick = (event: MouseEvent) => { 
        var target = event.target as HTMLElement;
        if (target.tagName !== "BUTTON") return;

        target.toggleAttribute("inactive");

        this.storeInactiveTags();

        this.setAttribute("inactive-tags", this.loadInactiveTags().join(","));

        this.dispatchEvent(new CustomEvent("tag-toggle"));
    }

    private storeInactiveTags = () => { 
        var inactiveTags = this.querySelectorAll("button[inactive]");
        var inactiveTagsArray = Array.from(inactiveTags).map(tag => tag.textContent);

        localStorage.setItem("inactiveTags", JSON.stringify(inactiveTagsArray));
    }

    private loadInactiveTags = () => { 
        var tags = localStorage.getItem("inactiveTags");

        if (!tags) return [];
        return JSON.parse(tags) as string[];
    }

    private view = (tags: string[], inactives: string[]) => `
        ${tags.map(tag => `<button type="button" class="tag-button" ${(inactives.indexOf(tag) !== -1 ? " inactive" : "")}>${tag}</button>`).join("")}
    `;


}

customElements.define(TagToggleElement.tag, TagToggleElement);