import { UserPromptElement } from "Feature/Dialog";
import "./CRMListElement.less";
import { shortDate, shortDateTime, shortSystemDate } from 'Library/Formatting';
import { TagToggleElement } from "./TagToggleElement";

export class CRMListElement extends HTMLElement {
    public static tag = "crm-list";
    private _tbody: HTMLElement;
    private _thead: HTMLElement;
    private _currentPage: number = 0;
    private _pageSize: number = 100;
    private _query: string = "";
    private _searchBox: HTMLInputElement;
    private _tagInput: HTMLInputElement;
    private _tagToggle: TagToggleElement;

    public connectedCallback() {
        const mailKeys = this.getAttribute("promotion-mails").split(",");
        this.innerHTML = this.view(mailKeys);

        this._tagToggle = this.querySelector(TagToggleElement.tag) as TagToggleElement;

        this._tagToggle.addEventListener("tag-toggle", this.refresh);

        this._searchBox = this.querySelector("input[name=filter]") as HTMLInputElement;
        this._tagInput = this.querySelector("input[name=tags]") as HTMLInputElement;

        this._tbody = this.querySelector("tbody") as HTMLElement;
        this._thead = this.querySelector("thead") as HTMLElement;

        this.addEventListener("click", this.onClick);
        this._searchBox.addEventListener("keyup", this.onKeyUp);


        this.refresh();


        this._tagInput.addEventListener("keyup", this.onTagKeyUp);

    }

    private selectAll = () => {
        const element = this.querySelector("input[name=select-all]") as HTMLInputElement;
        const checked = element.getAttribute("checked") === "true";

        // select trs which does not have attribute unsubscribed
        const trs = this.querySelectorAll("tr:not([unsubscribed])");

        trs.forEach(tr => {
            const checkbox = tr.querySelector("input[name=selected]") as HTMLInputElement;

            if (!checkbox)
                return;

            checkbox.setAttribute("checked", checked ? "true" : "false");
        });

    }

    private onTagKeyUp = async (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const tag = this._tagInput.value;
            this._tagInput.value = "";


            const selected = this.querySelectorAll("input[name=selected]:checked");
            const crmIds = Array.from(selected).map(s => s.closest("tr").getAttribute("crm-id"));


            const userResponse = await UserPromptElement.show("Add tags", `Add tag ${tag} to ${selected.length} items?`, [
                { id: "no", title: "Cancel", intent: "secondary" },
                { id: "yes", title: `Add ${tag} tags`, intent: "primary" }
            ]);

            if (userResponse !== "yes")
                return;


            const response = await fetch("/admin/crm/tags", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    tag: tag,
                    crmIds: crmIds
                })
            });

            if (response.ok) {
                const status = await response.text();

                if (status !== "") {
                    alert(status);
                }

                this.refresh();
                this.refreshTags();
            } else {
                alert("Failed to send emails: " + response.statusText);
            }
        }
    }

    private onKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this._currentPage = 0;
            this._query = this._searchBox.value;
            this.refresh();
        }
    }

    disconnectedCallback() {


    }

    private restoreCollapsed = () => {
        const ths = Array.from(this.querySelectorAll("th"));

        ths.forEach(th => {
            const title = th.innerText;
            console.log("kk", `crm-${title}-collapsed`);
            if (window.localStorage.getItem(`crm-${title}-collapsed`) === "true") {
                this.collapse(title);
            }
        });
    }

    private collapse(columnName: string) {
        const th = Array.from(this.querySelectorAll(`th`)).filter(m => m.innerText === columnName)[0];

        const result = th.toggleAttribute("collapsed");
        const index = Array.from(th.parentElement.children).indexOf(th);
        const tds = this.querySelectorAll("td:nth-child(" + (index + 1) + ")");
        tds.forEach(td => td.toggleAttribute("collapsed"));

        return result;
    }

    private removeTag = async (crmId: string, tag: string) => {
        const userResponse = await UserPromptElement.show("Remove tag", `Remove ${tag} from ${crmId}?`, [
            { id: "no", title: "Cancel", intent: "secondary" },
            { id: "yes", title: `Remove`, intent: "primary" }
        ]);

        if (userResponse !== "yes")
            return;

        const response = await fetch(`/admin/crm/${crmId}/tag`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tag: tag
            })
        });

        if (response.ok) {
            const status = await response.text();

            if (status !== "") {
                alert(status);
            }

            this.refreshTags();
            this.refresh();
        } else {
            alert("Failed to remove tag: " + response.statusText);
        }
    }

    private onClick = async (event: MouseEvent) => {
        const element = event.target as HTMLElement;

        if (element.attributes.getNamedItem("name")?.value === "select-all") {
            this.selectAll();
            return;
        }

        if (element.tagName == "TH") {
            const title = element.innerText;
            this.collapse(title);
            window.localStorage.setItem(`crm-${title}-collapsed`, element.hasAttribute("collapsed") ? "true" : "false");
        }


        if (element.tagName !== "BUTTON")
            return;

        const crmId = element.closest("tr")?.getAttribute("crm-id");
        const email = element.closest("tr")?.getAttribute("email");

        switch (element.getAttribute("name")) {
            case "remove-tag": {
                const crmId = element.closest("tr")?.getAttribute("crm-id");
                const tag = element.getAttribute("tag");
                this.removeTag(crmId, tag);
                break;
            }
            case "add-case": {
                const emailField = this.querySelector("input[name=email]") as HTMLInputElement;

                if (await this.addCase(emailField.value))
                    emailField.value = "";

                break;
            }
            case "mass-send": {
                const mailKey = (this.querySelector("select[name=mail-key]") as HTMLInputElement).value;
                const selected = this.querySelectorAll("input[name=selected]:checked");
                const emails = Array.from(selected).map(s => s.getAttribute("value"));
                const count = emails.length;

                if (count === 0)
                    return;

                const userResponse = await UserPromptElement.show("Send emails", `Send ${count} <b>${mailKey}</b> emails?`, [
                    { id: "no", title: "Cancel", intent: "secondary" },
                    { id: "yes", title: `Send ${count} mails`, intent: "primary" }
                ]);

                if (userResponse !== "yes")
                    return;

                const reqeustModel = {
                    mailKey: mailKey,
                    to: emails.map(m => ({ email: m, name: m }))
                }

                const response = await fetch("/admin/promotion-mail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(reqeustModel)
                });

                if (response.ok) {
                    const status = await response.text();

                    if (status !== "") {
                        alert(status);
                    }

                    this.refresh();
                } else {
                    alert("Failed to send emails: " + response.statusText);
                }

                break;

            }
            case "paging": {
                const page = element.getAttribute("page");
                this._currentPage = parseInt(page);
                this.refresh();
                break;
            }
            case "comment": {
                this.comment(crmId, (element.getAttribute("comment") || ""));
                break;
            }

            case "geoip": {
                this.geoIP(element.getAttribute("ip"));
                break;
            }
        }
    }

    private async geoIP(ip: string) {
        var search = new URLSearchParams();
        search.set("ipaddress", ip);

        const response = await fetch(`/admin/crm/geoip?${search.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok)
            alert("Failed to geo ip");
        else {
            this.refresh();
        }
    }

    private async comment(crmId: string, comment: string) {

        const result = prompt("Enter a comment", comment);

        if (!result)
            return;

        if (result !== comment) {
            await this.doComment(crmId, result);
        }
    }

    private async addCase(email: string) {
        const response = await fetch(`/admin/crm`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email: email })
        });

        if (!response.ok) {
            alert("Failed to add case");
            return false;
        } else {
            this.refresh();
            return true;
        }
    }

    private async doComment(crmId: string, comment: string) {
        const response = await fetch(`/admin/crm/${crmId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ comment: comment })
        });

        if (!response.ok)
            alert("Failed to comment session");
        else {
            this.refresh();
        }
    }

    private refreshTags = async () => {

        const response = await fetch(`/admin/crm/tags`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });


        if (!response.ok)
            alert("Failed to load tags");

        const result = await response.json() as string[];
        this._tagToggle.setAttribute("tags", result.map(m => m.toUpperCase()).join(","));

        const tagList = this.querySelector("#tagList") as HTMLDataListElement;
        tagList.innerHTML = result.map(tag => `<option value="${tag.toUpperCase()}">`).join("");
    }


    private refresh = async () => {
        var search = new URLSearchParams();

        search.set("from", (this._currentPage * this._pageSize).toString());
        search.set("size", this._pageSize.toString());
        search.set("query", this._query);

        const inactiveTags = this._tagToggle.getAttribute("inactive-tags");

        search.set("inactiveTags", inactiveTags);

        console.log("tags", inactiveTags);

        const response = await fetch("/admin/crm/list?" + search.toString(), {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok)
            alert("Failed to load sessions");

        const result = await response.json() as CRMViewModel;

        const headers = this.discoverHeaders(result.items);

        this._thead.innerHTML = this.headersView(headers);
        this._tbody.innerHTML = this.sessionsView(headers, result);
        this.restoreCollapsed();
    }

    private discoverHeaders = (crmItems: { [key: string]: any }[]): string[] => {
        const headers = new Set<string>();

        crmItems.forEach(crmItem => {
            Object.keys(crmItem).forEach(key => headers.add(key));
        });

        var headersArray = Array.from(headers).filter(m => m !== "Comment" && m !== "highlights");

        headersArray.sort();

        headersArray.push("Comment");


        return headersArray;
    }

    private formatDate = (source: string) => source ? shortDate(new Date(source)) : "-";

    private formatDateTime = (source: string) => source ? shortDateTime(new Date(source)) : "-";
    private formatSystemDate = (source: string) => source ? shortSystemDate(new Date(source)) : "";


    private static convertToUTC = (dateString) => {
        if (!dateString || dateString === "undefined")
            return undefined;

        const date = new Date(dateString);
        const utcString = date.toISOString();
        return utcString;
    }

    private sessionsView = (headers: string[], crm: CRMViewModel) => `
            ${crm.items.map(item => `
            <tr crm-id="${item["CRMId"] || ""}" email="${item["Email"]}" ${item["highlights"] ? item["highlights"] : ""}>
                <td>
                    <input type="checkbox" name="selected" value="${item["Email"] || ""}" />
                </td>
                ${headers.map(h => `<td ${this.createAttribute(h)}>${this.translateValue(item[h], h)}</td>`).join("")}
                <td>
                    <div class=actions>
                        <button type=button name=geoip title="GeoIP" ip="${item["IP"] || ""}">🌍</button> 
                        <button type=button name=comment title="Edit comment" comment="${item["Comment"] || ""}">💬</button> 
                        
                    </div>
                </td>
            </tr>
        `).join("")}

        <tr class=paging>
            <td colspan="${headers.length + 2}">
                ${crm.total} items
                ${this.getPages(crm.total).map(p => `<button name=paging type="button" page=${p}${p == this._currentPage ? " current" : ""}>${p + 1}</button>`).join("")}
            </td>
        </tr>
    `;

    private getPages = (total: number) => {
        const pageCount = Math.ceil(total / this._pageSize);
        const pages = [];
        for (let i = 0; i < pageCount; i++) {
            pages.push(i);
        }

        return pages;
    }

    private createAttribute = (source: string) => {
        // replace spaces with dashes
        source = source.replace(/ /g, "-");
        return source.toLocaleLowerCase();
    }

    private translateValue = (value: any, header: string) => {
        if (value === undefined)
            return "";

        if (value === null)
            return "";

        if (value === true)
            return "✔️";

        if (value === false)
            return "❌";

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,8}Z$/.test(value))
            return this.formatDateTime(value);

        if (header === "Tags") {
            var result = "";
            for (const tag of (value || "").split(",")) {
                result += `<div>${tag}<button type="button" name="remove-tag" tag="${tag}">✖️</button></div>`;
            }

            return result;
        }

        return value;
    }

    private headersView = (headers: string[]) => `
        <tr>
            <th>
                <input type="checkbox" name="select-all" />
            </th>
            ${headers.map(h => `<th ${this.createAttribute(h)}>${h}</th>`).join("")}
            <th>Actions</th>
        </tr>`;


    private view = (mailKeys) => `
        <header class="fieldset-section-header">
           
            <div class=actions>
                <fieldset>
                    <legend>Add case</legend>
                    <input type="text" name="email" placeholder="Email" />
                    <button type="button" name="add-case">Send</button>
                </fieldset>
                <fieldset>
                    <legend>Send mail</legend>
                    <select name=mail-key>
                        ${mailKeys.map(key => `<option>${key}</option>`).join("")}
                    </select>
                    <button type="button" name="mass-send">Send</button>
                </fieldset>
                <fieldset>
                    <legend>Tagging</legend>
                    <input type="text" name="tags" list="tagList">
                    <datalist id="tagList">
                        ${this.getAttribute("tags").split(",").map(tag => `<option value="${tag}">`).join("")}
                    </datalist>
                </fieldset>
            </div>
            <div class=filter>
                <fieldset>
                <legend>Search</legend>
                    <input type="text" name="filter" placeholder="email or comment" />
                    
                </fieldset>
            </div>

            <div class=tags>
                <fieldset>
                <legend>Tags</legend>
                    <${TagToggleElement.tag}
                        tags="${this.getAttribute("tags") || ""}"
                    >
                    </${TagToggleElement.tag}>
                    
                </fieldset>
            </div>
        </header>
        <table class="styled-table">
            <thead>
              
            </thead>
            <tbody>
            
            </tbody>
        </table>`;

}


customElements.define(CRMListElement.tag, CRMListElement);

interface CRMViewModel {
    total: number;
    items: { [key: string]: any }[];
}